import React from 'react'
import { navigate } from 'gatsby-link'
import Layout from '../../components/Layout'
import ContactForm from '../../components/ContactForm'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error))
  }

  render() {
    return (
      <Layout>
        <section className="section">
          <div className="container">
            <div className="content ">
              <h1>Kontakt</h1>
              <div className="columns is-multiline">






                {/* <form className="column is-half"
                name="contact"
                method="post"
                action="/contact/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
              >
                
                <input type="hidden" name="form-name" value="contact" />
                <div hidden>
                  <label>
                    Don’t fill this out:{' '}
                    <input name="bot-field" onChange={this.handleChange} />
                  </label>
                </div>
                <div className="field">
                  <label className="label" htmlFor={'name'}>
                    Imię
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type={'text'}
                      name={'name'}
                      onChange={this.handleChange}
                      id={'name'}
                      required={true}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label" htmlFor={'email'}>
                    Email
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type={'email'}
                      name={'email'}
                      onChange={this.handleChange}
                      id={'email'}
                      required={true}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label" htmlFor={'message'}>
                    Wiadomość
                  </label>
                  <div className="control">
                    <textarea
                      className="textarea"
                      name={'message'}
                      onChange={this.handleChange}
                      id={'message'}
                      required={true}
                    />
                  </div>
                </div>
                <div className="field">
                  <button className="button is-link" type="submit">
                    Send
                  </button>
                </div>
              </form> */}

                <ContactForm />

                <div className="column is-half ">
                  <h4>email</h4>
                  <p>
                    <a href="mailto:stalmarkspj@gmail.com">
                      stalmarkspj@gmail.com
                  </a>
                  </p>

                  <h4>Telefon</h4>
                  <p>
                    <a href="tel:+48 15 8428198">+48 15 842 81 98</a>
                  </p>
                  <p>
                    <a href="tel:+48 15 8135858">+48 15 813 58 58</a>
                  </p>

                  <h4>Tel./ Fax</h4>
                  <p>
                    <a href="tel:+48 15 8135678">+48 15 813 56 78</a>
                  </p>
                </div>


                <div className="column is-half">
                  <h4>"Stalmark" E.Steć R.Steć sp.j.</h4>
                  <p>ul. Eugeniusza Kwiatkowskiego 1B,</p>
                  <p>37-450 Stalowa Wola, Poland</p>
                  <p>KRS: 0000117765</p>
                  <p>Regon: 830219167</p>
                  <p>NIP: 865-10-05-178</p>
                </div>

              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
